<template>
  <form>
    <div class="form-group">
      <label>Username</label>
      <input type="text" class="form-control" v-model="username">
    </div>
    <div class="form-group my-2">
      <label>Password</label>
      <input type="password" class="form-control" v-model="password">
    </div>
    <button type="submit" class="btn btn-primary mt-2" @click.prevent.stop="login">Accedi</button>
  </form>
</template>

<script>

export default {
  name: "FormAuthentication",
  data() {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    isValid() {
      return this.username && this.password;
    },
  },
  methods: {
    login() {
      if (this.isValid) {
        this.$store.dispatch("auth/login", {username: this.username, password: this.password})
      }
    }
  }
}
</script>

<style scoped>

</style>