import api from './api';
import {API_URLS} from "@/lib/constants";
import Season from "@/models/Season";

async function getData(promise) {
    try {
        const response = await promise;
        return response.data;
    } catch (error) {
        const message = error.response.data || "Errore nella procedura";
        throw new Error(message);
    }
}

class AppService {
    async getActiveSeason() {
        const data = await getData(api.get(API_URLS.SEASON));
        return new Season(data);
    }

    getBulletins() {
        return getData(api.get(API_URLS.BULLETINS));
    }

    publishBulletin() {
        return getData(api.post(API_URLS.BULLETIN_PUBLISH));
    }

    getWeekInfo(dt = null) {
        return getData(api.get(API_URLS.WEEK_INFO, {params: {dt: dt}}));
    }

    getIstatPraticabilita(bulletin, dt = null) {
        return getData(api.get(API_URLS.ISTAT_INFO, {params: {bulletin: bulletin, dt: dt}}));
    }

    getIstatChiusure(bulletin, dt = null) {
        return getData(api.get(API_URLS.ISTAT_CONCESSIONI, {params: {bulletin: bulletin, dt: dt}}));
    }

    getIstatModellistica(bulletin, dt = null) {
        return getData(api.get(API_URLS.ISTAT_MODELLISTICA, {params: {bulletin: bulletin, dt: dt}}));
    }

    getE015_PM10() {
        return getData(api.get(API_URLS.PM10, {params: {}}));
    }

    searchForComuni(q) {
        return getData(api.get(API_URLS.COMUNI_SEARCH, {params: {q: q}}));
    }

    getInfoForComune(payload) {
        return getData(api.post(API_URLS.COMUNI_INFO, payload));
    }

    resetComuniInfo(payload) {
        return getData(api.post(API_URLS.COMUNI_INFO_RESET, payload));
    }

    saveComuniInfo(payload) {
        return getData(api.post(API_URLS.COMUNI_INFO_SAVE, payload));
    }

    getProvince(payload) {
        return getData(api.get(API_URLS.PROVINCE, payload));
    }

    downloadBulletin(cod_prov, bulletin) {
        return getData(api.get(API_URLS.BULLETINS_EXPORT, {
            responseType: 'blob',
            params: {cod_prov: cod_prov, bulletin: bulletin}
        }))
    }

}

export default new AppService();
