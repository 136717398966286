<template>
  <div class="progress-bar-wrapper">
    <div class="progress-bar">
      <span class="bar">
        <span class="progress"></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar"
}
</script>

<style scoped>

</style>