import appService from "@/services/app.service";
import moment from "moment";
import {DATE_FORMATS} from "@/lib/constants";


function canAddItem(arr, value, fieldName = 'pro_com') {
    return !(value && arr.some(item => item[fieldName] === value));
}

async function updateSelectionCollection(collection, bulletin, pro_com, area_id) {

    if (!canAddItem(collection, pro_com)) {
        return {status: false, collection: []};
    }

    const payload = {
        bulletin: bulletin,
        cod_istat: pro_com,
        area_id: area_id
    }

    const data = await appService.getInfoForComune(payload);

    const arr = [...collection];

    data.forEach(item => {
        arr.push({
            pro_com: item.pro_com,
            comune: item.comune,
            p_days: item.chiusure.filter(item => item.tag === 'p').map(item => {
                return {
                    day_as_date: moment(item.iso_date),
                    day: moment(item.iso_date).format(DATE_FORMATS.DATE_FORMAT_DD_MMM),
                    status: item.result
                }
            }),
            t_days: item.chiusure.filter(item => item.tag === 't').map(item => {
                return {
                    day_as_date: moment(item.iso_date),
                    day: moment(item.iso_date).format(DATE_FORMATS.DATE_FORMAT_DD_MMM),
                    status: item.result
                }
            }),
            p_days_count: item.chiusure_count
        })
    })

    arr.sort((a, b) => {
        return a.comune.localeCompare(b.comune);
    });

    return {status: true, collection: arr};
}

const downloadBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = filename
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove()
}

export {
    canAddItem,
    downloadBlob,
    updateSelectionCollection
}