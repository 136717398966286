import ls from "localstorage-slim";

const USER_TAG = "ersafreflui_user";

function readLocalStorage(key, defaultValue = null) {
    let payload = ls.get(USER_TAG, {decrypt: true});
    if (payload) {
        return payload[key];
    }
    return defaultValue;
}

class TokenService {
    getLocalRefreshToken() {
        return readLocalStorage("refresh_token");
    }

    getLocalAccessToken() {
        return readLocalStorage("access_token");
    }

    updateLocalAccessToken(token) {
        const user = this.getUser();
        user.access_token = token;
        this.setUser(user);
    }

    getUser() {
        return ls.get(USER_TAG, {decrypt: true});
    }

    setUser(user) {
        ls.set(USER_TAG, user, {encrypt: true});
    }

    removeUser() {
        ls.remove(USER_TAG);
    }
}

export default new TokenService();
