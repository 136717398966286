<template>
  <div class="map">
    <!-- Card left -->
    <div class="card-container-left bg-light">
      <div v-if="!isPm10" class="card p-2 mt-2" style="width: 18rem;">
        <BulletinsSelector @currentBulletinNumber="handleCurrentBulletinNumber"/>
        <WeekDaysSelector/>
      </div>
      <div class="card bg-light p-2 mt-2" style="width: 18rem;">
        <MapLayersSelect/>
      </div>
    </div>
    <!-- //Card left -->
    <!-- Card right-->
    <div v-if="!isPm10" class="card-container-right card-container-right-top bg-light">
      <div class="card" style="width: 30rem;">
        <bulletin-export :bulletin-number="currentBulletinNumber"/>
      </div>
    </div>

    <div v-if="!isPm10" class="card-container-right card-container-right-bottom bg-light">
      <div class="card" style="width: 30rem;">
        <municipality-detail/>
      </div>
    </div>
    <!-- //Card right-->
    <popup
        :ref="(el) => popupEl = el"
        :content="popupContent"
        @on-close="onClosePopup"
    />
  </div>
</template>

<script>
import Map from 'ol/Map';
import View from 'ol/View';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import XYZ from 'ol/source/XYZ';
import TopoJSON from 'ol/format/TopoJSON';
import VectorSource from 'ol/source/Vector';
import Select from 'ol/interaction/Select';
import {click} from 'ol/events/condition';
import {fromLonLat} from 'ol/proj';
import WeekDaysSelector from "@/components/WeekDaysSelector";
import BulletinsSelector from "@/components/BulletinsSelector";
import {mapGetters} from "vuex";
import {API_URLS, FIELD_NAMES, LAYER_NAMES, MAP_NAMES} from "@/lib/constants";
import {ALL_STYLES} from "@/lib/styles";
import {stopLoading} from "@/store";
import MapLayersSelect from "@/components/MapLayersSelect";
import MunicipalityDetail from "@/components/MunicipalityDetail";
import {GeoJSON} from "ol/format";
import {Overlay} from "ol";
import Popup from "@/components/Popup.vue";
import BulletinExport from "@/components/BulletinExport";

const COLUMN_NAME_AREA_ID = "area2021";
const SELECT_AREA_ONCE = true;
let vectorComuniSourceIsReady = false;
let overlay = null;

export default {
  name: "MapControl",
  components: {BulletinExport, Popup, MunicipalityDetail, MapLayersSelect, BulletinsSelector, WeekDaysSelector},
  props: {
    enablePointerMoveListing: {
      type: Boolean, required: false, default() {
        return false;
      }
    }
  },
  data() {
    return {
      map: null,
      selectInteraction: null,
      shiftKeyPressed: false,
      enabledMapPointerMoveListening: false,
      popupEl: null,
      popupContent: '',
      currentBulletinNumber: null
    }
  },
  computed: {
    ...mapGetters({
      chiusure: "chiusureForIstat",
      pm10: "pm10data",
      userLogged: "auth/userLogged",
      currentMap: "currentMap",
      selectedItemsOnMap: "selectedItemsOnMap",
      currentSelectableLayer: "currentSelectableLayer"
    }),
    layerAree() {
      return this.getLayerByName(LAYER_NAMES.AREE);
    },
    layerComuni() {
      return this.getLayerByName(LAYER_NAMES.COMUNI);
    },
    layerProvince() {
      return this.getLayerByName(LAYER_NAMES.PROVINCE);
    },
    layerZone() {
      return this.getLayerByName(LAYER_NAMES.ZONE);
    },
    layerPm10() {
      return this.getLayerByName(LAYER_NAMES.PM10);
    },
    isPm10() {
      return this.currentSelectableLayer === LAYER_NAMES.PM10;
    }
  },
  methods: {
    onClosePopup() {
      this.$store.dispatch("resetSelectionOnMap");
      overlay.setPosition(undefined);
    },
    showPm10Layer() {
      const vectorSource = this.layerPm10.getSource();
      vectorSource.clear();
      const features = new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857"
      }).readFeatures(this.pm10);
      if (features.length > 0) {
        const _style = features[0].get("style");
        const is_green = _style === 'VERDE';
        this.layerPm10.setStyle(is_green ? ALL_STYLES.style_pm10_true : ALL_STYLES.style_pm10_false);
        this.layerProvince.setVisible(!is_green);
      }
      vectorSource.addFeatures(features);
      this.layerComuni.setVisible(false);
      this.layerPm10.setVisible(true);
      this.enabledMapPointerMoveListening = true;
      this.$store.dispatch("setCurrentSelectableLayer", LAYER_NAMES.PM10);
      stopLoading();
    },
    updateLayer() {
      this.enabledMapPointerMoveListening = false;
      this.layerProvince.setVisible(false);
      this.layerPm10.setVisible(false);
      this.layerComuni.setVisible(true);
      if (this.currentSelectableLayer === LAYER_NAMES.PM10) {
        this.$store.dispatch("setCurrentSelectableLayer", LAYER_NAMES.COMUNI);
      }
      if (!vectorComuniSourceIsReady) {
        return false;
      }
      const source = this.layerComuni.getSource();
      source.forEachFeature(feature => {
        const cod_istat = feature.getProperties()[FIELD_NAMES.COD_ISTAT];
        const styleName = this.getValueByIstat(cod_istat)
        const p = {};
        p[FIELD_NAMES.STYLE_NAME] = styleName;
        feature.setProperties(p, false);
      })
      this.layerComuni.setStyle(this.setStyle);
    },
    setStyle(feature) {
      const style_name = feature.getProperties()[FIELD_NAMES.STYLE_NAME];
      return ALL_STYLES[style_name] || ALL_STYLES.style_comuni_false;
    },
    getValueByIstat(cod_istat) {
      let v = undefined;

      if (Object.keys(this.chiusure).length > 0) {
        v = this.chiusure[cod_istat];

        if ([MAP_NAMES.MAP_1.label, MAP_NAMES.MAP_4.label].includes(this.currentMap)) {
          if ([true, false].includes(v)) {
            //delete this.chiusure[cod_istat];
            return v === true ? 'style_comuni_true' : 'style_comuni_false';
          }
        } else if (this.currentMap === MAP_NAMES.MAP_2.label) {
          if (v !== undefined) {
            //delete this.chiusure[cod_istat];
            return `style_comuni_chiusure_${v}`;
          }
        }
      }

      return 'style_comuni_false';
    },
    getLayerByName(name) {
      return this.map.getLayers()
          .getArray()
          .find(l => l.get("name") === name);
    },
    initMap() {

      const vectorAreeSource = new VectorSource({
        url: API_URLS.AREE,
        format: new TopoJSON({
          layers: [LAYER_NAMES.AREE],
        }),
        overlaps: false,
      })

      const vector_aree = new VectorLayer({
        name: LAYER_NAMES.AREE,
        source: vectorAreeSource,
        style: ALL_STYLES.style_aree,
        visible: this.userLogged,
        selectable: () => {
          return this.currentSelectableLayer === LAYER_NAMES.AREE;
        }
      })

      const vectorComuniSource = new VectorSource({
        url: API_URLS.COMUNI,
        format: new TopoJSON({
          layers: [LAYER_NAMES.COMUNI],
        }),
        overlaps: false,
      })

      const vector_comuni = new VectorLayer({
        name: LAYER_NAMES.COMUNI,
        source: vectorComuniSource,
        style: ALL_STYLES.style_comuni_false,
        visible: true,
        selectable: () => {
          return this.currentSelectableLayer === LAYER_NAMES.COMUNI;
        }
      })

      vectorComuniSource.on("featuresloadend", () => {
        vectorComuniSourceIsReady = true;
        this.updateLayer();
      })

      const vectorProvinceSource = new VectorSource({
        url: API_URLS.PROVINCEGEO,
        format: new TopoJSON({
          layers: [LAYER_NAMES.PROVINCE],
        }),
        overlaps: false,
      })

      const vector_province = new VectorLayer({
        name: LAYER_NAMES.PROVINCE,
        source: vectorProvinceSource,
        style: ALL_STYLES.style_province,
        visible: this.isPm10,
        selectable: () => {
          return this.currentSelectableLayer === LAYER_NAMES.PROVINCE;
        }
      })

      const vectorZoneSource = new VectorSource({
        url: API_URLS.ZONE,
        format: new TopoJSON({
          layers: [LAYER_NAMES.ZONE],
        }),
        overlaps: false,
      })

      const vector_zone = new VectorLayer({
        name: LAYER_NAMES.ZONE,
        source: vectorZoneSource,
        style: ALL_STYLES.style_zone,
        visible: this.userLogged,
        selectable: () => {
          return this.currentSelectableLayer === LAYER_NAMES.ZONE;
        }
      })

      const vector_pm10 = new VectorLayer({
        name: LAYER_NAMES.PM10,
        source: new VectorSource(),
        visible: this.userLogged,
        style: ALL_STYLES.style_comuni_true,
        selectable: () => {
          return this.currentSelectableLayer === LAYER_NAMES.PM10;
        }
      })

      const _layers = [
        new TileLayer({
          source: new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          })
        }),
        vector_comuni,
        vector_zone,
        vector_aree,
        vector_province,
        vector_pm10
      ]

      overlay = new Overlay({
        element: this.popupEl.$el,
        autoPan: {
          animation: {
            duration: 250
          }
        }
      })

      const map = new Map({
        target: this.$el,
        layers: _layers,
        renderer: 'webgl',
        overlays: [overlay],
        view: new View({
          projection: 'EPSG:3857',
          center: fromLonLat([10, 45.5]),
          zoom: 8
        })
      })

      map.on("rendercomplete", () => {
        stopLoading();
      })

      const vm = this;

      map.on("click", (e) => {
        vm.shiftKeyPressed = e.originalEvent.shiftKey;
      })

      const store = this.$store;

      const select = new Select({
        condition: click,
        layers: function (layer) {
          const selectable = layer.get("selectable");
          return selectable && selectable();
        },
        filter: function (ft, layer) {
          const layerName = layer.get("name");
          if (layerName === LAYER_NAMES.AREE) {
            const areaId = ft.get(COLUMN_NAME_AREA_ID);
            if (SELECT_AREA_ONCE) {
              if (!vm.shiftKeyPressed) {
                store.dispatch("resetSelectionOnMap");
              }
              store.dispatch("addToSelectionOnMap", {area_id: areaId});
            } else {
              vector_comuni.getSource().getFeatures()
                  .filter(ft => ft.get(COLUMN_NAME_AREA_ID) === areaId)
                  .forEach(ft => {
                    store.dispatch("addToSelectionOnMap", {pro_com: ft.get("PRO_COM")});
                  })
            }
            return false;
          } else if (layerName === LAYER_NAMES.COMUNI) {
            return true;
          } else if (layerName === LAYER_NAMES.PM10) {
            return !vm.enablePointerMoveListing;
          } else {
            return false;
          }
        }
      })

      select.on('select', (e) => {
        const isPm10 = vm.currentSelectableLayer === LAYER_NAMES.PM10;
        if (isPm10) {
          const messages = [];
          e.selected.forEach(ft => {
            messages.push(buildMapPointerMoveMessage(ft));
          })
          if (messages.length === 0) {
            vm.onClosePopup();
          } else {
            this.popupContent = messages.join("</br>");
            const coordinate = e.mapBrowserEvent.coordinate;
            overlay.setPosition(coordinate);
          }
        } else {
          //const shiftKeyPressed = e.mapBrowserEvent.originalEvent.shiftKey;
          //const selection = this.getSelection(e.target);
          const pro_com_list = e.deselected.map(ft => ft.get('PRO_COM'));
          this.$store.dispatch("removeFromSelectionOnMap", pro_com_list);
          e.selected.forEach(ft => {
            this.$store.dispatch("addToSelectionOnMap", {pro_com: ft.get("PRO_COM")});
          })
        }

      });

      map.getInteractions().extend([select]);

      /*--- Popover ------
      let selected = null;
      const status = document.getElementById('status');

      const highlightStyle = new Style({
        fill: new Fill({
          color: 'rgba(255,255,255,0.7)',
        }),
        stroke: new Stroke({
          color: '#3399CC',
          width: 3,
        }),
      });

       */

      const buildMapPointerMoveMessage = (feature) => {
        const isReg = feature.get("style") === 'VERDE';
        let denominazione = 'Regione Lombardia';
        let limitazione = 'Nessuna limitazione';
        if (!isReg) {
          denominazione = feature.get("denominazione");
          limitazione = feature.get("limitazione");
        }
        return `${denominazione} - ${limitazione}`;
      }
      const pointerMoveListener = (e) => {
        if (!vm.enabledMapPointerMoveListening) {
          return false;
        }
        if (e.dragging) {
          // the event is a drag gesture, this is handled by openlayers (map move)
          return false;
        }
        map.forEachFeatureAtPixel(e.pixel, (feature) => {
          const message = buildMapPointerMoveMessage(feature);
          console.debug(message);
          return true;
        })
      }

      if (this.enablePointerMoveListing) {
        map.on('pointermove', pointerMoveListener)
      }

      this.map = map;
      this.selectInteraction = select;
    },
    getSelection(selectControl = this.selectInteraction) {
      return selectControl.getFeatures().getArray().map(s => s.get('PRO_COM'));
    },
    refreshSelection() {
      const l = this.selectedItemsOnMap.length;

      if (l === 0) {
        this.selectInteraction.getFeatures().clear();
      } else {
        const olSelection = this.getSelection();
        const itemsSelection = this.selectedItemsOnMap.map(s => s["pro_com"]);
        const featuresToAdd = itemsSelection.filter(x => !olSelection.includes(x));
        const featuresToRemove = olSelection.filter(x => !itemsSelection.includes(x));

        if (featuresToAdd.length === 0 && featuresToRemove.length === 0) {
          return true;
        }

        this.layerComuni.getSource().getFeatures()
            .filter(ft => {
              const cod_istat = ft.get(FIELD_NAMES.COD_ISTAT);
              return featuresToAdd.includes(cod_istat) || featuresToRemove.includes(cod_istat);
            })
            .forEach(ft => {
              const arr = this.selectInteraction.getFeatures();
              const cod_istat = ft.get(FIELD_NAMES.COD_ISTAT);
              if (featuresToAdd.includes(cod_istat)) {
                arr.push(ft);
              } else {
                arr.remove(ft);
              }
            })
      }
    },
    handleCurrentBulletinNumber(bulletinNumber){
      this.currentBulletinNumber = bulletinNumber;
    }
  },
  mounted() {
    this.initMap();
  },
  watch: {
    chiusure: function () {
      this.updateLayer();
    },
    pm10: function () {
      this.showPm10Layer();
    },
    selectedItemsOnMap: function () {
      this.refreshSelection();
    },
    currentSelectableLayer: function () {
      this.$store.dispatch("resetSelectionOnMap");
    },
    userLogged: function () {
      const b = this.userLogged;
      this.layerAree.setVisible(b);
      this.layerZone.setVisible(b);
    }
  }
}
</script>
