import moment from "moment";
import {DATE_FORMATS} from "@/lib/constants";

class Season {
    constructor({
                    season,
                    dt_first_date,
                    dt_start,
                    dt_end,
                    dt_off_start,
                    dt_off_end,
                    total_days_off,
                    sync_days_of_week,
                    period_for_editing,
                    is_active,
                    by_pass_client_validation
                }) {
        this.season = season;
        this.dt_first_date = moment(dt_first_date);
        this.dt_start = moment(dt_start);
        this.dt_end = moment(dt_end).subtract(1, "days");
        this.dt_off_start = moment(dt_off_start);
        this.dt_off_end = moment(dt_off_end).subtract(1, "days");
        this.total_days_off = total_days_off;
        this.sync_days_of_week = sync_days_of_week;
        this.period_for_editing = period_for_editing;
        this.is_active = is_active;
        this.by_pass_client_validation = by_pass_client_validation;
    }

    get seasonFirstDate() {
        return this.dt_start;
    }

    get seasonLastDate() {
        return this.dt_end;
    }

    get seasonOffLimitFirstDate() {
        return this.dt_off_start;
    }

    get seasonOffLimitLastDate() {
        return this.dt_off_end;
    }

    get seasonTotalDays() {
        return this.dt_end.diff(this.dt_start, "days") + 1;
    }

    get seasonChiusure() {
        return this.total_days_off;
    }

    get seasonConcessioni() {
        return this.seasonTotalDays - this.seasonChiusure;
    }

    seasonLeftDays(dt = moment(), excluded = true) {
        return this.seasonLastDate.diff(dt, "days") + (excluded ? 0 : 1);
    }

    isCritical(dt, n, excluded = true) {
        const _daysLeft = this.seasonLeftDays(dt, excluded);
        return this.seasonChiusure > (n + _daysLeft);
    }

    isOffLimit(dt = moment()) {
        if (this.by_pass_client_validation) {
            return false;
        }
        return dt >= this.seasonOffLimitFirstDate && dt <= this.seasonOffLimitLastDate;
    }

    dayIsValid(dt = moment()) {
        return this.seasonFirstDate <= dt && dt <= this.seasonLastDate;
    }

    isSyncDay(dt_current, dt = moment()) {
        if (this.by_pass_client_validation) {
            return true;
        }
        if (this.isOffLimit(dt_current)) {
            return false;
        }
        return this.sync_days_of_week.includes(dt.isoWeekday());
    }

    isEditingSlot(dt_current, dt = moment()) {
        if (this.by_pass_client_validation) {
            return true;
        }
        if (this.isSyncDay(dt_current, dt)) {
            const dates = this.period_for_editing.map(h => {
                const dt_as_string = dt.format(DATE_FORMATS.DATE_FORMAT_ISO) + " " + h;
                return moment(dt_as_string, DATE_FORMATS.DATE_FORMAT_ISO_HH_MM).toDate();
            })
            return dt >= dates[0] && dt <= dates[1];
        }
        return false;
    }

    printInfo(dt = moment()) {
        console.debug(`validazione client attiva? ${!this.by_pass_client_validation ? 'Sì' : 'No'}`);
        console.debug(`chisure previste per legge: ${this.seasonChiusure}`);
        console.debug(`concessioni massime consentite: ${this.seasonConcessioni}`);
        console.debug(`oggi è un giorno off limit? ${this.isOffLimit(dt) ? 'Sì' : 'No'}`);
        console.debug(`oggi è un buon giorno per fare editing? ${this.isSyncDay(dt, dt) ? 'Sì' : 'No'}`);
        console.debug(`ora è un buon momento per fare editing? ${this.isEditingSlot(dt, dt) ? 'Sì' : 'No'}`);
    }

    printWarning(dt, n, comune, printInLine = true) {
        const _leftDays = this.seasonLeftDays(dt);
        const _isCritical = this.isCritical(dt, n);
        const messages = [
            `comune: ${comune}`,
            `data: ${dt.format(DATE_FORMATS.DATE_FORMAT_ISO)}`,
            `chiusure: ${n}`,
            `giorni mancanti: ${_leftDays}`,
            `situazione critica: ${_isCritical ? 'Sì' : 'No'}`,
        ]
        if (printInLine) {
            const msg = messages.join(" ");
            console.debug(msg);
        } else {
            messages.forEach(msg => {
                console.debug(msg)
            });
        }
    }
}

export default Season;