<template>
  <div>
    <div v-if="bulletinIsValid">
      <Header/>
      <ProgressBar v-if="showProgressBar"/>
      <ToastsMessages
          :show-message="false"
          :show-close-button="true"
          top="95px"
          bottom="auto"
          right="auto"
          left="calc(50% - 162px)"
          max-width="324px"
          :class="{'show':toastPm10.visible && !showProgressBar }"
          @close="closeToastPm10"
      >
        <template v-slot:message>
          <!--
          Consulta <strong><a :href="href4banner"
                              :title="title4banner"
                              target="_blank">QUI</a></strong> le limitazioni da Qualità dell'Aria -->
          {{ pm10Message }}
        </template>
      </ToastsMessages>

      <MapControl
          :enable-pointer-move-listing="false"
      />

      <ToastsMessages
          :message="toastMessageMultiselect"
          class="align-items-center text-white bg-primary"
          :class="{'show':showMultiselectToast && !showProgressBar }"
          @close="closeToastMultiselect"
      />
    </div>
    <div v-else>
      Bolletino non valido
    </div>
  </div>
</template>

<script>

import MapControl from "./components/MapControl";
import Header from "@/components/Header";
import ProgressBar from "@/components/ProgressBar";
import ToastsMessages from "@/components/ToastsMessages";
import {mapGetters} from "vuex";
import {EVENT_NAMES, MAP_NAMES} from "@/lib/constants";


export default {
  name: 'App',
  components: {
    ToastsMessages,
    ProgressBar,
    Header,
    MapControl
  },
  computed: {
    ...mapGetters({
      showProgressBar: "isLoading",
      bulletinIsValid: "bulletinIsValid",
      pm10hasError: "pm10hasError"
    }),
    href4banner() {
      return "https://www.infoaria.regione.lombardia.it/infoaria/#/stato-attivazione"
    },
    title4banner() {
      return "Stato di attivazione delle misure temporanee e media PM10 (µg/m³) per provincia"
    },
    pm10Message() {
      return this.pm10hasError ? this.toastPm10.errorMessage : this.toastPm10.successMessage;
    }
  },
  data() {
    return {
      showMultiselectToast: true,
      toastMessageMultiselect: "Per selezionare o deselezionare più comuni all'interno della mappa tenere premuto il tasto MAIUSC",
      toastPm10: {
        visible: false,
        successMessage: "LIMITAZIONI AGRICOLTURA: STATO ATTUALE",
        errorMessage: "LIMITAZIONI AGRICOLTURA: DATI ARPA NON DISPONIBILI"
      }
    }
  },
  methods: {
    closeToastMultiselect() {
      this.showMultiselectToast = false;
    },
    closeToastPm10() {
      this.toastPm10.visible = false;
    },
    selectedLayerChangedListener(e) {
      const {layerName} = e.detail;
      this.toastPm10.visible = layerName === MAP_NAMES.MAP_5.label;
    }
  },
  beforeMount() {
    window.document.addEventListener(EVENT_NAMES.SELECTED_LAYER_CHANGED, this.selectedLayerChangedListener)
  },
  beforeUnmount() {
    window.document.removeEventListener(EVENT_NAMES.SELECTED_LAYER_CHANGED, this.selectedLayerChangedListener);
  }
}
</script>
