import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store";
import "./assets/App.scss";
import moment from "moment";
import setupInterceptors from "@/services/setupInterceptors";
import VueLogger from 'vuejs3-logger';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

moment.locale(window.navigator.language || 'it-IT');

setupInterceptors(store);

store.dispatch("init");

createApp(App)
    .use(VueLogger, options)
    .use(store)
    .mount('#app')

window.addEventListener("unload", () => {
    store.dispatch("auth/logout");
})