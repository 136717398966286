<template>
  <header>
    <div class="navbar-header">
      <div class="navbar-brand">
        <a href="http://www.ersaf.lombardia.it" target="_blank" class="logo_ersaf">
          <img src="./../assets/logo-ersaf-single.png" alt="Logo Ersaf">
        </a>
        <a href="http://www.regione.lombardia.it/" target="_blank" class="logo_regione">
          <img src="./../assets/logo-regione-single.png" alt="Logo Regione Lombardia"></a>
        <a href="https://www.e015.regione.lombardia.it/" target="_blank">
          <img src="./../assets/logoE015.jpg" alt="Ecosistema Digitale E015">
        </a>
      </div>
      <div class="navbar-menu">
        <div v-if="!userLogged" class="login">
          <button class="login-button" @click="clickForLogin" title="Login">
              <span class="login-button_svg">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                       viewBox="0 0 24 24">
                        <path
                            d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z"/>
                  </svg>
              </span>
          </button>
        </div>
        <div class="card login-card" v-if="isActive">
          <form-authentication/>
        </div>
        <div v-if="userLogged" class="logout">
          <span class="user-logged">{{ currentUser }}</span>
          <button class="logout-button" title="logout" @click.prevent.stop="clickForLogout">
            <span class="logout-button_svg">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                   viewBox="0 0 24 24">
              <path
                  d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z"/>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import FormAuthentication from "@/components/FormAuthentication";
import {mapGetters} from "vuex";

export default {
  name: "Header",
  components: {FormAuthentication},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      userLogged: "auth/userLogged"
    })
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    clickForLogin() {
      this.isActive = !this.isActive;
    },
    clickForLogout() {
      this.$store.dispatch("auth/logout");
    }
  },
  watch: {
    userLogged: function (newVal) {
      if (newVal === true && this.isActive === true) {
        this.isActive = false;
      }
    }
  }
}
</script>
