<template>
  <div class="bulletins_selector">
    <h6 class="mb-0 border-bottom mb-2 pb-1" style="line-height: 1.125rem;">
      <strong class="mb-1">
        Bollettino Nitrati n° {{ currentBulletinNumber }} emesso il: <i>{{selectedBulletinDay}}</i>
      </strong>
      <strong class="mt-2">
        Giornata visualizzata in mappa il: <i>{{currentFormattedDay}}</i>
      </strong>
    </h6>
    <label class="form-label mb-0 mt-1">Seleziona data emissione bollettino:</label>
    <select
        class="form-select form-select-sm"
        v-model="selected"
        v-on:change="onChange"
    >
      <option
          v-for="(item, index) in days"
          :key="index"
          :value="index"
      >
        {{ item }}
      </option>
    </select>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import {DATE_FORMATS} from "@/lib/constants";

export default {
  name: "BulletinsSelector",
  data() {
    return {
      selected: 0,
    }
  },
  computed: {
    ...mapGetters({
      bulletins: "bulletins",
      currentBulletin: "currentBulletin",
      currentDay: "currentDay"
    }),
    currentFormatDate(){
      return DATE_FORMATS.L;
    },
    currentBulletinDate(){
      return this.bulletins[this.selected] ? this.bulletins[this.selected].iso_date: null;
    },
    currentBulletinNumber() {
      return this.days.length - this.selected;
    },
    days() {
      return this.bulletins.map(dd => moment(dd.iso_date).format(this.currentFormatDate))
    },
    selectedBulletinDay(){
      return this.currentBulletinDate ? moment(this.currentBulletinDate).format(this.currentFormatDate) : '--';
    },
    currentFormattedDay() {
      return this.currentDay.format(this.currentFormatDate);
    },
  },
  methods: {
    onChange() {
      this.$store.dispatch("setCurrentWeek", {bulletin: this.currentBulletinNumber, dt: this.currentBulletinDate});
    }
  },
  watch:{
    currentBulletin(id){
      this.selected = this.bulletins.findIndex(item => item.bulletin_id === id);
      this.$emit("currentBulletinNumber", this.currentBulletinNumber);
      console.log("watch", this.currentBulletinNumber)
    }
  }
}
</script>
