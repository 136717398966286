<template>
  <div class="toast align-items-center"
       :class="[toastStyle, toastPosition]"
       :style="{'top': top, 'bottom':bottom, 'left': left, 'right': right, 'max-width': maxWidth }"
       v-if="elementVisible"
  >
    <div class="d-flex">
      <div class="toast-body">
        {{ message }}
        <slot name="message"></slot>
      </div>
      <button
          v-if="showCloseButton"
          type="button"
          class="btn-close me-2 m-auto"
          aria-label="Close"
          @click="closeToast"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToastsMessages",
  props: {
    message: {
      type: String,
      default: null
    },
    toastStyle: {
      type: String,
      default: 'warning'
    },
    toastPosition: {
      type: String,
      default: 'bottom'
    },
    autoHide: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 2000
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showMessage: {
      type: Boolean,
      default: true
    },
    top: {
      type: String,
      default: null
    },
    bottom: {
      type: String,
      default: null
    },
    left: {
      type: String,
      default: null
    },
    right: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: null
    }
  },
  methods: {
    closeToast() {
      this.$emit('close')
    }
  },
  data() {
    return {
      elementVisible: true
    }
  },
  created() {
    if (this.autoHide) {
      setTimeout(() => this.elementVisible = false, this.timeout)
    }
  }
}
</script>

<style scoped>

</style>
