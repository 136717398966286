<template>
  <div class="week_days_selector">
    <p class="mt-2 mb-0 pb-1">
      Seleziona giornata visualizzata in mappa:
    </p>
    <WeekDaysSelectorCard
        :days-p="days_p"
        :days-t="days_t"
        v-on:selected="onSelected"
    />
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import WeekDaysSelectorCard from "@/components/WeekDaysSelectorCard";
import {DATE_FORMATS} from "@/lib/constants";

export default {
  name: "WeekDaysSelector",
  components: {WeekDaysSelectorCard},
  props: {
    currentDay: {
      type: Date,
      required: false,
      default() {
        return new Date();
      }
    }
  },
  computed: {
    ...mapGetters({
      currentBulletin: "currentBulletin",
      currentWeek: "currentWeek",
      currentMap: "currentMap",
      p_days: "p_days",
      t_days: "t_days"
    }),
    days_p() {
      return this.p_days.map(dd => moment(dd.iso_date).format(DATE_FORMATS.L));
    },
    days_t() {
      return this.t_days.map(dd => moment(dd.iso_date).format(DATE_FORMATS.L));
    }
  },
  methods: {
    onSelected(index) {
      if (index === -1) {
        console.warn("avvisare utente!")
        return false;
      }
      const iso_date = this.currentWeek.at(index).iso_date;
      this.$store.dispatch("setCurrentDay", {mapName: this.currentMap, bulletin: this.currentBulletin, dt: iso_date});
    }
  }
}
</script>

<style scoped>

</style>