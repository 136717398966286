import {Fill, Stroke, Style} from "ol/style";

const stroke_for_all = new Stroke({
    color: 'rgba(0, 0, 0, .5)',
    width: 2
})

const style_comuni_true = new Style({
    fill: new Fill({
        color: 'rgba(145, 207, 96, 0.5)'
    }),
    stroke: stroke_for_all
})

const style_comuni_false = new Style({
    fill: new Fill({
        color: 'rgba(252, 141, 89, 0.5)'
    }),
    stroke: stroke_for_all
})

const style_pm10_false = new Style({
    fill: new Fill({
        color: 'rgb(255,0,102, 0.5)'
    }),
    stroke: stroke_for_all
})

const all_styles = {};

[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(x => {
    let c;
    if (x === 1) {
        c = 'rgba(0, 0, 0, 1)'
    } else if (x === 11) {
        c = 'rgba(255, 255, 255, 1)'
    } else {
        const cc = Math.round(255 / 9 * x);
        c = `rgba(${cc}, ${cc}, ${cc}, 1)`;
    }
    const k = `style_comuni_chiusure_${x}`;
    all_styles[k] = new Style({
        fill: new Fill({
            color: c
        }),
        stroke: stroke_for_all
        /*
        stroke: new Stroke({
            color: c, width: 1
        })
         */
    })
})

const style_zone = new Style({
    stroke: new Stroke({
        color: 'rgba(204, 0, 255, 0)',
        width: 0
    })
})


const style_aree = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0)'
    }),
    stroke: new Stroke({
        //color: 'rgb(51, 102, 255, 1)',
        color: 'rgb(44,70,180)',
        width: 4
    })
})

const ALL_STYLES = Object.freeze({
    ...all_styles,
    style_aree: style_aree,
    style_province: style_aree,
    style_zone: style_zone,
    style_comuni_false: style_comuni_false,
    style_comuni_true: style_comuni_true,
    style_pm10_false: style_pm10_false,
    style_pm10_true: style_comuni_true
})

export {
    ALL_STYLES
}
