import api from "./api";
import TokenService from "./token.service";
import {API_URLS} from "@/lib/constants";

class AuthService {

    async login({username, password}) {
        const response = await api.post(API_URLS.AUTH_LOGIN, {username: username, password: password});
        if (response.data.access_token) {
            TokenService.setUser(response.data);
        }
        return response.data;
    }

    async logout() {
        await api.post(API_URLS.AUTH_LOGOUT);
        TokenService.removeUser();
        return true;
    }
}

export default new AuthService();