import Vuex from 'vuex';
import moment from 'moment';
import {DATE_FORMATS, LAYER_NAMES, MAP_NAMES} from "@/lib/constants";
import {updateSelectionCollection} from "@/lib/utils";
import appService from "@/services/app.service";
import {auth} from "./auth.module";
import {parse} from "qs";

function getFilteredCurrentWeek(cw, tag) {
    return cw.filter(item => item.tag === tag);
}

function parse_query_params() {
    let _search = window.location.search;
    if (_search) {
        _search = parse(_search.substring(1));
        const isInteger = /^\d+$/.test(_search.bulletin);
        if (isInteger) {
            return parseInt(_search.bulletin)
        }
        const isIsoDate = /^\d{4}-\d{2}-\d{2}$/.test(_search.bulletin);
        if (isIsoDate) {
            return moment(_search.bulletin).format(DATE_FORMATS.DATE_FORMAT_ISO);
        }
        throw new Error("bulletin parameter not valid");
    }
    return true;
}

const state = {
    bulletinIsValid: true,
    currentDay: moment(new Date()),
    currentSeason: null,
    currentBulletin: -1,
    currentWeek: [],
    bulletins: [],
    chiusureForIstat: {},
    pm10: null,
    currentMap: MAP_NAMES.MAP_1.label,
    loading: false,
    selectedItemsOnMap: [],
    currentSelectableLayer: null
}

const getters = {
    bulletinIsValid(state) {
        return state.bulletinIsValid;
    },
    bulletins(state) {
        return state.bulletins;
    },
    currentSeason(state) {
        return state.currentSeason;
    },
    currentBulletin(state) {
        return state.currentBulletin;
    },
    currentDay(state) {
        return state.currentDay;
    },
    currentWeek(state) {
        return state.currentWeek;
    },
    p_days(state) {
        return getFilteredCurrentWeek(state.currentWeek, 'p');
    },
    t_days(state) {
        return getFilteredCurrentWeek(state.currentWeek, 't');
    },
    chiusureForIstat(state) {
        return state.chiusureForIstat;
    },
    currentMap(state) {
        return state.currentMap;
    },
    isLoading(state) {
        return state.loading;
    },
    selectedItemsOnMap: (state) => {
        return state.selectedItemsOnMap;
    },
    currentSelectableLayer: (state) => {
        return state.currentSelectableLayer;
    },
    pm10data(state) {
        return state.pm10;
    },
    pm10hasError(state) {
        return state.pm10 === null;
    }
}

const mutations = {
    setActiveSeason(state, season) {
        season.printInfo();
        state.currentSeason = season;
    },
    setBulletins(state, bulletins) {
        state.bulletins = bulletins;
    },
    setCurrentDay(state, dt) {
        state.currentDay = dt;
    },
    setCurrentBulletin(state, bulletin) {
        state.currentBulletin = bulletin;
    },
    setCurrentWeek(state, currentWeek) {
        state.currentWeek = currentWeek;
    },
    setChiusure(state, chiusure) {
        state.chiusureForIstat = chiusure;
    },
    setPm10(state, data) {
        state.pm10 = data;
    },
    setCurrentMap(state, mapName) {
        state.currentMap = mapName;
    },
    setLoading(state, value) {
        state.loading = value;
    },
    addToSelectionOnMap(state, arr) {
        state.selectedItemsOnMap = arr;
    },
    removeFromSelectionOnMap(state, pro_com_list) {
        if (pro_com_list.length === 0) {
            return false;
        }
        const arr = [...state.selectedItemsOnMap];
        pro_com_list.forEach(pro_com => {
            const idx = arr.findIndex(ii => ii.pro_com === pro_com);
            arr.splice(idx, 1);
        })
        state.selectedItemsOnMap = arr;
    },
    resetSelectionOnMap(state) {
        state.selectedItemsOnMap = [];
    },
    setCurrentSelectableLayer(state, layerName) {
        state.currentSelectableLayer = layerName;
    },
    setBulletinStatus(state, value) {
        state.bulletinIsValid = value;
    }
}

const actions = {
    async loadActiveSeason({commit}) {
        const season = await appService.getActiveSeason();
        commit("setActiveSeason", season);
    },
    async loadBulletins({dispatch, commit}) {
        const bulletins = await appService.getBulletins();
        commit("setBulletins", bulletins);
        if (bulletins && bulletins.length > 0) {
            try {
                const id_or_date = parse_query_params();
                let b;
                if (id_or_date === true) {
                    b = bulletins[0];
                } else {
                    b = bulletins.find(item => item.bulletin_id === id_or_date || item.iso_date === id_or_date);
                }
                if (!b) {
                    throw new Error("bulletin not found")
                }
                dispatch("setCurrentWeek", {bulletin: b.bulletin_id, dt: b.iso_date});
            } catch {
                commit("setBulletinStatus", false);
            }
        }
    },
    async loadDataForSingleDayAndMap({dispatch, commit}, {mapName, bulletin, dt}) {

        dispatch("setLoading", true);

        if (typeof dt !== "string") {
            dt = dt.format(DATE_FORMATS.DATE_FORMAT_ISO);
        }

        if (mapName === MAP_NAMES.MAP_1.label) {
            const data = await appService.getIstatPraticabilita(bulletin, dt);
            commit("setChiusure", data);
        } else if (mapName === MAP_NAMES.MAP_2.label) {
            const data = await appService.getIstatChiusure(bulletin, dt);
            commit("setChiusure", data);
        } else if (mapName === MAP_NAMES.MAP_3.label) {
            console.warn(`${mapName} not managed`)
        } else if (mapName === MAP_NAMES.MAP_4.label) {
            const data = await appService.getIstatModellistica(bulletin, dt);
            commit("setChiusure", data);
        } else if (mapName === MAP_NAMES.MAP_5.label) {
            const data = await appService.getE015_PM10();
            if (data.status) {
                commit("setPm10", data.data);
            }
        }
    },
    setCurrentDay({dispatch, commit}, {mapName, bulletin, dt}) {
        commit("setCurrentDay", moment(dt));
        dispatch("loadDataForSingleDayAndMap", {mapName, bulletin, dt});
    },
    async setCurrentWeek({commit}, {bulletin, dt}) {
        commit("setCurrentBulletin", bulletin);
        const currentWeek = await appService.getWeekInfo(dt);
        commit("setCurrentWeek", currentWeek);
    },
    setCurrentMap({dispatch, commit}, {mapName, bulletin, dt}) {
        commit("setCurrentMap", mapName);
        dispatch("loadDataForSingleDayAndMap", {mapName, bulletin, dt});
    },
    setLoading({commit}, value) {
        commit("setLoading", value);
    },
    async addToSelectionOnMap({commit, getters}, {pro_com = null, area_id = null}) {
        const {
            status,
            collection
        } = await updateSelectionCollection(getters.selectedItemsOnMap, getters.currentBulletin, pro_com, area_id);
        if (status) {
            commit("addToSelectionOnMap", collection);
        }
    },
    removeFromSelectionOnMap({commit}, pro_com_list) {
        commit("removeFromSelectionOnMap", pro_com_list);
    },
    resetSelectionOnMap({commit}) {
        commit("resetSelectionOnMap");
    },
    setCurrentSelectableLayer({commit}, layerName) {
        commit("setCurrentSelectableLayer", layerName);
    },
    reloadData({dispatch, getters}, reset = true) {
        if (reset) {
            dispatch("resetSelectionOnMap");
        }
        dispatch("loadDataForSingleDayAndMap", {
            mapName: getters.currentMap,
            bulletin: getters.currentBulletin,
            dt: getters.currentDay
        });
    },
    init({dispatch}) {
        dispatch("setCurrentSelectableLayer", LAYER_NAMES.COMUNI)
        dispatch("loadActiveSeason");
        dispatch("loadBulletins");
    }
}

const store = new Vuex.Store({
    modules: {auth},
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
});

export function startLoading() {
    store.dispatch("setLoading", true);
}

export function stopLoading() {
    store.dispatch("setLoading", false);
}

export default store;