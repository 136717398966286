import AuthService from '../services/auth.service';
import TokenService from "../services/token.service";

const user = TokenService.getUser();

export const auth = {
    namespaced: true,
    state: {
        status: {
            loggedIn: user !== null
        },
        user: user
    },
    getters: {
        accessToken(state) {
            return state.accessToken;
        },
        refreshToken(state) {
            return state.refreshToken;
        },
        currentUser(state) {
            return state.user && state.user.user ? state.user.user.username: null;
        },
        userLogged(state) {
            return state.status.loggedIn;
        },
    },
    actions: {
        async login({dispatch, commit}, {username, password}) {
            try {
                const user = await AuthService.login({username: username, password: password});
                commit('loginSuccess', user);
            } catch (ex) {
                commit('loginFailure');
            }
            dispatch("init", null, {root: true});
            return user;
        },
        async logout({dispatch, commit}) {
            try {
                await AuthService.logout();
            } catch (err){
                console.warn(err);
            } finally {
                commit('logout');
                dispatch("init", null, {root: true});
            }
        },
        refreshToken({commit}, accessToken) {
            commit('refreshToken', accessToken);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        refreshToken(state, accessToken) {
            state.status.loggedIn = true;
            state.user = {...state.user, accessToken: accessToken};
        }
    }
};
