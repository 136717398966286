<template>
  <div class="map-layers-select">
    <label class="form-label">Modalità mappa:</label>
    <div class="list-group list-group-flush">
      <div v-for="(item, index) in options"
           :key="index"
           class="list-group-item"
      >
        <div class="form-check">
          <input
              class="form-check-input"
              type="radio"
              v-model="selected"
              :value="index"
              v-on:change="onLayerChange"
          />
          <label class="form-check-label">
            {{ item }}
          </label>
          <span v-if="isPm10(item)">&nbsp;
            <a
                href="https://www.infoaria.regione.lombardia.it/infoaria/#/stato-attivazione"
                target="_blank"
                title="Consulta qui le limitazioni da Qualità dell'Aria"
            >link</a>
          </span>
        </div>
      </div>
    </div>
    <!--select
        class="form-select"
        v-model="selected"
        v-on:change="onLayerChange"
    >
      <option
          v-for="(item, index) in options"
          :key="index"
          :value="index"
      >
        {{ item }}
      </option>
    </select-->

  </div>
</template>

<script>
import {EVENT_NAMES, MAP_NAMES} from "@/lib/constants";
import {mapGetters} from "vuex";

export default {
  name: "MapLayersSelect",
  data() {
    return {
      selected: 0
    }
  },
  computed: {
    ...mapGetters({
      currentBulletin: "currentBulletin",
      currentDay: "currentDay",
      userLogged: "auth/userLogged"
    }),
    options() {
      return Object.values(MAP_NAMES)
          .filter(item => item.is_valid && (!item.for_admin || item.for_admin && this.userLogged))
          .map(item => item.label);
    }
  },
  methods: {
    onLayerChange() {
      const mapName = this.options[this.selected];
      const payload = {
        mapName: mapName,
        bulletin: this.currentBulletin,
        dt: this.currentDay
      }
      this.$store.dispatch("setCurrentMap", payload);
      // signal-slot in vanilla Javascript
      const e = new CustomEvent(EVENT_NAMES.SELECTED_LAYER_CHANGED, {bubbles: false, detail: {layerName: mapName}});
      window.document.dispatchEvent(e)
    },
    isPm10(item) {
      return item === MAP_NAMES.MAP_5.label;
    }
  },
  watch: {
    userLogged: function () {
      if (this.selected !== 0) {
        this.selected = 0;
        this.onLayerChange();
      }
    }
  }
}
</script>