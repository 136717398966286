<template>
  <div class="bulletin-export p-3 mb-2">
    <p class="mb-0"><strong>{{ cardTitle }} </strong></p>
    <div class="row gx-1 align-items-end">
      <div class="col-md-10 mt-2">
        <select class="form-select" v-model="selectedItem">
          <option selected :value="null">Seleziona una provincia</option>
          <option v-for="o in options" :value="o.cod_prov" :key="o.cod_prov">{{ o.denominazione }}</option>
        </select>
      </div>
      <div class="col-md-2">
        <button
            :disabled="!selectedItem"
            type="submit"
            title="Scarica bollettino corrente"
            class="btn btn-primary px-2"
            style="max-width: 72px;
              width: 72px;
              text-align: center;"
            @click.stop.prevent="downloadItem"
        >Scarica
        </button>
      </div>
    </div>
  </div>
  <toasts-messages
      toast-position="top"
      @close="closeToast"
      :toast-style="toastStyle"
      :class="{'show':toastShow }"
      :message="toastMessage"
  />
</template>

<script>
import ToastsMessages from "@/components/ToastsMessages";
import AppService from "@/services/app.service";
import {API_URLS} from "../lib/constants";
import {downloadBlob} from "../lib/utils";

export default {
  name: "BulletinExport",
  components: {
    ToastsMessages
  },
  props: {
    bulletinNumber: Number,
  },
  data() {
    return {
      cardTitle: 'Esportazione bollettino',
      localSelectableLayer: null,
      toastShow: false,
      toastStyle: 'success',
      toastMessage: '',
      selectedItem: null,
      options: []
    }
  },
  methods: {
    async loadProvince() {
      return this.options = await AppService.getProvince();
    },
    async downloadItem(use_blob = true, reset_selection = true) {
      const payload = {cod_prov: this.selectedItem, bulletin: this.bulletinNumber}
      try {
        if (use_blob) {
          const filename = `bollettino_${payload.bulletin}_${payload.cod_prov}.xlsx`
          const blob = await AppService.downloadBulletin(payload.cod_prov, payload.bulletin)
          downloadBlob(blob, filename)
        } else {
          const q = new URLSearchParams()
          const url = `/api${API_URLS.BULLETINS_EXPORT}?${q.toString()}`
          window.open(url, '_blank')
        }
        this.showHideToast('Bollettino scaricato');
      } catch (ex) {
        this.showHideToast(ex.toString(), 'error');
      }
      if (reset_selection) {
        this.selectedItem = null
      }
    },
    closeToast() {
      this.toastShow = false
    },
    showHideToast(message, style = 'success', timeout = 2000) {
      this.toastStyle = style;
      this.toastMessage = message;
      this.toastShow = true;
      setTimeout(() => this.toastShow = false, timeout);
    }
  },
  mounted() {
    this.loadProvince();
  },
  watch: {}
}
</script>
